
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="row">
      <div class="col-md-12" style="padding-left: 50px;">
        <b-card class="card-icon  mb-30" style="padding-left: 50px;">
          <h3>Reports</h3>
          <hr/>
          <div class="row" >
            <div class="col-md-4">
              <ul>
                <li>
                  <a style="text-decoration: underline;" href="/reports/accrual">Accrual Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="/reports/redemption">Overall Redemption Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="/reports/parkingRedemption">Parking Redemption Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="/reports/reversedTransaction">Reversed transactions Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="/reports/pendingTransaction">Pending transactions Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="/reports/users">Users Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="/reports/customers">Customers Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="/reports/newRegistration">New Registrations Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="/reports/bonusPointsReport">Bonus Points Report</a>
                </li>
                <li>
                  <a style="text-decoration: underline;" href="/reports/voucherUsageReport">Voucher Usage Report</a>
                </li>
                <!--<li>
                  <a style="text-decoration: underline;" href="/app/pages/merchantsReport">Merchants Report</a>
                </li>

               -->
              </ul>


            </div>
          </div>

        </b-card>
      </div>
    </div>


  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'




export default {
  data() {
    return {
      disabled:false,
      isLoading: false,
      fullPage: true,
      columns:["logo","name","phoneNumber","email","openingTime","closingTime","category","floorLevel","status","options"],
      outlets:[],
      users:[],
      floor_levels:[0,1,2,3,4],
      outlet:{
        id:0,
        name:"",
        phoneNumber:"",
        email:"",
        website:"",
        facebook:"",
        twitter:"",
        instagram:"",
        youtube:"",
        description:"",
        shopNo:"",
        openingTime:"",
        closingTime:"",
        logo:"",
        banner:"",
        category:"",
        floorLevel:"",
        loyalty_points:"",
        redemption_value:"",
      },
      categories:[],
      logo:"",
      banner:"",
      status:""
    };
  },
  components: {
    Loading,
    DatePicker
  },
  mounted (){
    this.getCategoriesData();
    this.getOutletData();
  },
  methods: {
    ...mapActions(["getCategories","addOutlet","getOutlets", "deleteOutlet","updateOutletStatus"]),

    openUpdateOutletStatus(status, outlet){



      this.status = status;
      this.outlet = outlet;

      let self = this;
      this.$bvModal.msgBoxConfirm('Confirm outlet status update ?')
        .then(value => {

          if(value){
            self.updateOutletStatusData();
          }

        })
        .catch(err => {
          // An error occurred
        })
    },

    updateOutletStatusData(){
      let self = this;

      this.isLoading = true;

      this.updateOutletStatus({status: this.status, outletId: this.outlet.id })
        .then(function () {
          self.isLoading = false;

          self.$bvToast.toast("Outlet details updated successfully.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

          self.getOutletData();
        })
        .catch(function (error) {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });

        })
    },

    handleLogoImageUpload(){
      this.logo = this.$refs.logo.files[0];
    },
    handleBannerImageUpload(){
      this.banner = this.$refs.banner.files[0];
    },
    getCategoriesData(){
      let self = this;
      this.getCategories()
        .then(function (categories) {
          self.categories = categories;
        })
        .catch(function (error) {


          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    confirmDeleteOutlet(){
      this.isLoading = true;

      let self = this;


      this.deleteOutlet({outlet_id: this.outlet.id}).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Outlet deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getOutletData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        })

    },

    openDeleteOutlet(outlet){

      this.outlet = outlet;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.outlet.name +' from system')
        .then(value => {

          if(value){
            self.confirmDeleteOutlet();
          }

        })
        .catch(err => {
          // An error occurred
        })
    },

    getOutletData(){
      let self = this;
      this.getOutlets()
        .then(function (outlets) {


          self.outlets = outlets;


        })
        .catch(function (error) {
          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    }

  }
};
</script>
